
import { defineComponent } from 'vue';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { IonicSwiper } from '@ionic/vue';
import { getRelatedProducts } from '@/store/firestoreActions';

SwiperCore.use([IonicSwiper, Pagination]);

import 'swiper/swiper-bundle.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import Product from '@/components/Product.vue';

export default defineComponent({
  name: 'Offers',
  components: {
    Swiper,
    SwiperSlide,
    Product
  },
  props: ['product'],
  data() {
    return {
      relatedProducts: []
    }
  },
  beforeMount(){
    getRelatedProducts(this.product, this.$route.params.id).then((res: any) => {
      this.relatedProducts = res;
    });
  },
   watch:{
    $route(to, from){
      if(to.name === "SingleProduct"){
        getRelatedProducts(this.product, this.$route.params.id).then((res: any) => {
          this.relatedProducts = res;
        });
      }
    }
  },
});
