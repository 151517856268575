<template>
  <div class="container">
    <div class="invisible-box">
        <h2 class="section-title"><strong>También te puede interesar</strong></h2>
        <swiper class="related-slider" :pagination="true" :spaceBetween="0" :breakpoints="{
                778: {
                    slidesPerView: 1,
                },
                // when window width is <= 999px
                991: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 4,
                },                
            }">
            <swiper-slide class="slide" v-for="product in relatedProducts" :key="product.id">
              <Product :data="product"></Product>
            </swiper-slide>
        </swiper>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { IonicSwiper } from '@ionic/vue';
import { getRelatedProducts } from '@/store/firestoreActions';

SwiperCore.use([IonicSwiper, Pagination]);

import 'swiper/swiper-bundle.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import Product from '@/components/Product.vue';

export default defineComponent({
  name: 'Offers',
  components: {
    Swiper,
    SwiperSlide,
    Product
  },
  props: ['product'],
  data() {
    return {
      relatedProducts: []
    }
  },
  beforeMount(){
    getRelatedProducts(this.product, this.$route.params.id).then((res: any) => {
      this.relatedProducts = res;
    });
  },
   watch:{
    $route(to, from){
      if(to.name === "SingleProduct"){
        getRelatedProducts(this.product, this.$route.params.id).then((res: any) => {
          this.relatedProducts = res;
        });
      }
    }
  },
});
</script>

<style scoped>
  .related-slider{
    padding-bottom: 2rem;
  }

</style>