<template>
  <ion-page>
    <Header id="top"></Header>
    <ion-content :fullscreen="true">

      <section id="information">
        <div class="container">
          <ion-grid>
            <ion-row>
              <ion-col size="12" size-lg="6">
                <div class="invisible-box">
                  <swiper :pagination="true" @swiper="setProductSlider">
                      <!-- start slider item -->
                      <swiper-slide v-for="image in product.images" :key="image" class="main-swiper">
                        <div class="singlePorduct-image">
                          <a :href="image"><img :src="image || 'https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/thumbnails%2Fno-image_1080x1080.webp?alt=media'" :alt="product.name"></a>
                        </div>
                      </swiper-slide>
                      <!-- end slider item -->
                  </swiper>

                  <swiper :navigation="true" :slidesPerView="3" :spaceBetween="10" class="horizontal-swiper">
                      <swiper-slide v-for="(image, index) in product.images" :key="image" >
                        <div class=" singlePorduct-thumbnail">
                          <img class="w-100" :src="image || 'https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/thumbnails%2Fno-image_1080x1080.webp?alt=media'" :alt="product.name" @click="goToSlide(index)" style="cursor: pointer">
                        </div>
                      </swiper-slide>
                  </swiper>
                </div>
              </ion-col>
              <ion-col size="12" size-lg="6">
                <div class="invisible-box">
                  <div class="flex">
                    <p>Inicio > <router-link v-if="product.category" :to="'/products?c='+ product.category.id">{{ product.category.name }}</router-link><span v-if="product.subcategory"> > <router-link :to="'/products?c='+ product.category.id + '&sc=' + product.subcategory.id">{{ product.subcategory.name }}</router-link></span></p>
                    <Rating :modelValue="product.globalRating || 4" :readonly="true" :cancel="false" class="globalStars" />
                  </div>

                  <div style="padding-top: 4rem !important; padding-bottom: 2rem !important">
                    <h6>ref: {{ product.refCode }}</h6>
                    <h3 style="padding: 0.5rem 0 !important"><strong>{{ product.name }}</strong></h3>
                    <h4>{{ actualPrice.toFixed(2) }}€</h4>
                  </div>

                  <div class="variation" v-for="(variation, v) in cartProduct.variations" :key="variation.key">
                    <h5><strong>{{ variation.variation }}</strong></h5>
                    <ul class="options">
                        <li v-for="(option, o) in variation.options" :key="option.key" @click="setVariationOption(v, o)" :class="option.selected ? 'selected' : ''">
                            {{ option.option }}
                        </li>
                    </ul>
                  </div>

                  <div class="compra">
                      <ion-buttons>
                        <ion-button @click="lessQty">
                          <ion-icon slot="icon-only" :icon="removeOutline"></ion-icon>
                        </ion-button>
                        <ion-button>
                          <p>{{ cartProduct.quantity }}</p>
                        </ion-button>
                        <ion-button @click="plusQty">
                          <ion-icon slot="icon-only" :icon="addOutline"></ion-icon>
                        </ion-button>
                      </ion-buttons>
                      <ion-button color="secondary" shape="round" class="addToCart" :disabled="cartProduct.stock < cartProduct.quantity" @click="buy">
                        Añadir al Carrito
                        <ion-icon slot="end" color="light" :icon="cartOutline"></ion-icon>
                      </ion-button>
                  </div>

                  <p class="text-danger" style="padding-top: 1rem" v-if="cartProduct.stock < cartProduct.quantity">Lo sentimos ahora mismo no hay stock disponible. <router-link :to="'/contact'">Reservar</router-link></p>

                  <div class="flex" style="align-items: center; padding-top: 4rem">
                    <p>Etiquetas: <router-link v-for="(tag, t) in product.keywords" :key="tag" :to="'/search?s=' + encodeURI(tag)">{{ tag.toLowerCase() }}<span v-if="(t + 1) < product.keywords.length">, </span></router-link></p>
                    <!--<ion-buttons style="margin-left: auto;">
                      <ion-button>
                        <ion-icon color="dark" :icon="logoWhatsapp"></ion-icon>
                      </ion-button>
                      <ion-button>
                        <ion-icon color="dark" :icon="logoFacebook"></ion-icon>
                      </ion-button>
                      <ion-button>
                        <ion-icon color="dark" :icon="logoInstagram"></ion-icon>
                      </ion-button>
                      <ion-button>
                        <ion-icon color="dark" :icon="logoPinterest"></ion-icon>
                      </ion-button>
                    </ion-buttons>-->
                  </div>

                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </section>

      <section class="sm-section" id="especificaciones">
        <div class="container">
            <ion-segment scrollable @ionChange="segmentChanged($event)" class="segments" mode="md"  color="dark" :value="activeSegment">
              <ion-segment-button value="description" v-if="product.description">
                <ion-label>Descripción</ion-label>
              </ion-segment-button>
              <ion-segment-button value="specifications" v-if="product.specifications && product.specifications.length">
                <ion-label>Especificaciones</ion-label>
              </ion-segment-button>
              <ion-segment-button value="ratings">
                <ion-label>Valoraciones ({{ product.ratings && product.ratings.length || 0}})</ion-label>
              </ion-segment-button>
            </ion-segment>

            <div class="invisible-box" v-if="activeSegment === 'description'">
              <p v-html="product.description" style="overflow: hidden"></p>
            </div>

            <div class="invisible-box" v-if="activeSegment === 'specifications'">
                <ul v-for="specification in product.specifications" :key="specification">
                  <li>{{ specification.specification }}: {{ specification.value }}</li>
                </ul>
            </div>

            <div class="invisible-box" v-if="activeSegment === 'ratings'">
                <div class="box bg-light2 valoracion" v-for="rating in product.ratings" :key="rating">
                    <p><strong>{{ rating.name }}</strong></p>
                    <h6>{{ getDate(rating.date) }}</h6>
                    <Rating :modelValue="rating.rating" :readonly="true" :cancel="false" style="margin: 0.25rem 0;" />
                    <p>{{ rating.comment }}</p>
                </div>

                <ion-grid class="nueva-valoracion">
                  <ion-row>
                    <ion-col size="12">
                      <h5>Añadir Valoración</h5>
                      <p style="margin-bottom: 1rem">Su dirección de correo electrónico no será publicada. Los campos obligatorios están marcados (*)</p>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="12" size-lg="6">
                      <label for="name">Nombre*</label>
                      <InputText class="input secondary-input" id="name" type="text" v-model="newRating.name" />
                    </ion-col>
                    <ion-col size="12" size-lg="6">
                      <label for="email">Email*</label>
                      <InputText class="input" id="email" type="email" v-model="newRating.email" />
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="12">
                      <label for="rating">Valoración*</label>
                       <Rating v-model="newRating.rating" class="input" id="rating" :cancel="false" />
                    </ion-col>
                    <ion-col size="12">
                      <label for="comment">Comentario*</label>
                      <Textarea v-model="newRating.comment" class="input" id="comment"  :autoResize="true" rows="5" />
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="12">
                      <ion-button @click="addRating" shape="round" color="secondary">Enviar Valoración</ion-button>
                    </ion-col>
                  </ion-row>
                </ion-grid>              
            </div>
        </div>
      </section>

      <section class="sm-section" id="related">
        <RelatedProducts :product="product" v-if="product.category"></RelatedProducts>
      </section>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonicSwiper, IonButton, IonButtons, IonIcon, IonSegment, IonSegmentButton, menuController, IonGrid, IonRow, IonCol, IonLabel } from '@ionic/vue';
import { defineComponent } from 'vue';
import { logoWhatsapp, logoFacebook, logoInstagram, logoTwitter, logoPinterest, cartOutline, addOutline, removeOutline } from 'ionicons/icons';

import SwiperCore, { Navigation, Pagination, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/swiper-bundle.min.css';
import '@ionic/vue/css/ionic-swiper.css';

SwiperCore.use([IonicSwiper, Navigation, Pagination, Controller]);

import InputText from 'primevue/inputtext';
import Rating from 'primevue/rating';
import Textarea from 'primevue/textarea';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import RelatedProducts from '@/modules/shop/RelatedProducts.vue';

import moment from 'moment';
import {mapActions} from 'vuex';

import { getProduct } from '@/store/firestoreActions';
import MainSlider from '@/modules/shop/MainSlider.vue';


export default defineComponent({
  name: 'SingleProduct',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
    Swiper,
    SwiperSlide,
    IonButton,
    IonButtons,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    InputText,
    Rating,
    Textarea,
    RelatedProducts,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel
  },
  data() {
    return {
        ProductSlider: { value: null},
        activeSegment: 'description',
        cartProduct: {},
        actualPrice: 0,
        newRating: {
            rating: 4,
            date: moment().unix()
        },
        product: {
          images: [],
          variations: [],
          price: 0,
          keywords: [],
          name: '',
          ratings: [],
          description: ''
        }
    }
  },
  methods: {
    ...mapActions(['updateProduct', 'addCart']),

        goToSlide(index){
            this.ProductSlider.value.slideTo(index, 1000, true)
        },

        setProductSlider(swiper){
            this.ProductSlider.value = swiper;
        },

        // Variaciones
        setVariationOption(v, o){
            this.cartProduct.variations[v].options.forEach(opt => {
                opt.selected = false;
            });
            
            this.cartProduct.variations[v].options[o].selected = true;

            this.recalculate();
        },

        // Cantidad
        plusQty(){
            this.cartProduct.quantity = this.cartProduct.quantity + 1;
            this.recalculate();
        },
        lessQty(){
            if(this.cartProduct.quantity > 1){
                this.cartProduct.quantity = this.cartProduct.quantity - 1;
                this.recalculate();
            }
        },

        // Precio y stock
        recalculate(){
            let price = this.cartProduct.price;
            let stock = this.product.stock;

            this.cartProduct.variations.forEach(variation => {
                variation.options.forEach(option => {
                    if(option.selected){
                        price = option.price;
                        if(option.stock < stock){
                            stock = option.stock;
                        }
                    }
                })
            });

            this.actualPrice = this.cartProduct.quantity * price;
            this.cartProduct.stock = stock;
        },

        // Valoraciones
        addRating(){
            if(this.product.ratings){
                this.product.ratings.push(this.newRating);

                this.product.globalRating = 0;
                this.product.ratings.forEach(r => {
                    this.product.globalRating = this.product.globalRating + r.rating;
                });
                this.product.globalRating = this.product.globalRating / this.product.ratings.length;
            }
            this.updateProduct({ data: this.product, id: this.$route.params.id })
        },
        getDate(date){
            return moment(date).format('ll');
        },

        // Comprar
        async buy(){
            this.addCart(JSON.parse(JSON.stringify(this.cartProduct)));
            menuController.open('cartMenu');
        },
        segmentChanged(ev) {
          this.activeSegment = ev.detail.value;
        }
  },
  beforeMount(){
      getProduct(this.$route.params.id).then(res => {
        this.product = res;

        this.cartProduct = Object.assign({}, this.product);
        delete this.cartProduct.ratings;

        if(this.cartProduct.variations){
          this.cartProduct.variations.forEach(v => {
              v.options[0].selected = true;
              this.cartProduct.price = v.options[0].price;
          });
        }

        this.cartProduct.quantity = 1;
        this.actualPrice = this.cartProduct.price;

        if(this.product.description){
          this.activeSegment = 'description';
        } else if(this.product.specifications && this.product.specifications.length){
          this.activeSegment = 'specifications';
        } else {
          this.activeSegment = 'ratings';
        }


      });
  },
  mounted() {   
      moment.locale('es');
  },
  setup() {
    return {
      logoWhatsapp,
      logoFacebook,
      logoInstagram,
      logoTwitter,
      logoPinterest,
      cartOutline,
      addOutline,
      removeOutline
    }
  }
});
</script>

<style scoped>
  .variation{
    padding-top: 1rem;
  }

  .variation ul{
    padding: 0;
    margin: 0;
    padding-top: 0.5rem;
    list-style: none;
    display: block;
  }

  .variation ul li{
    cursor: pointer;
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    width: fit-content;
    padding: 0.5rem;
    border: 2px solid var(--ion-color-primary);
    min-width: 40px;
    text-align: center;
    font-size: 0.833rem;
  }

  .variation ul li.selected{
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
  }

  .compra{
    padding-top: 2rem;
    display: flex;
  }

  .compra .addToCart{
    align-self: center;
    margin-left: 1rem
  }

  .valoracion{
    max-width: 960px;
    margin: 1rem auto;
  }

  .nueva-valoracion{
    padding-top: 2rem;
    max-width: 960px;
    margin: 0 auto;
  }

  .globalStars{
    margin-left: auto;
    width: 136px;
  }
  
  .main-swiper{
    margin-bottom: 0.5rem;
  }

  .singlePorduct-image{
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 100% 0 0 0; /* 34.37% = 100 / (w / h) = 100 / (640 / 220) */
  }

  .singlePorduct-image img{
    display: block;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    object-position: center;
  }

  .singlePorduct-thumbnail{
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 100% 0 0 0; /* 34.37% = 100 / (w / h) = 100 / (640 / 220) */
  }

  .singlePorduct-thumbnail img{
    display: block;
    max-width: 100%;
    width: 100%;
    height: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    object-position: center;
  }


  @media (min-width: 960px) {
    #information{
      padding: 2rem 0;
    }

    .segments{
      justify-content: center;
    }
  }
</style>